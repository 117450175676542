/* src/components/Tabs.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


.tabs {
    display: flex;
    background-color: #FFFFFF;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: 'Poppins', sans-serif;
  }
  
  .tabs button {
    flex: 1;
    padding: 12px 16px;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .tabs button:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  
  .tabs button.active {
    background-color: #6200EE;
    color: #FFFFFF;
  }
  
  .tabs button:not(:last-child) {
    border-right: 1px solid #E0E0E0;
  }
  